.cv-fullpage-circle-loader {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100;
}

span.cv-fullpage-slider {
  width: 400px;
  height: 4px;
  background: var(--main-border-color);
  margin: 30px 0 0 0;
  position: relative;
}

span.cv-fullpage-slider:after {
  height: 100%;
  width: 0%;
  content: '';
  display: block;
  background-color: #0C9AFC;
  animation: slide 5s forwards;
}
