
:root {
    --converse-base: #fff;
    --main-background-color: #fff;
    --main-border-color: #dfe3e8;
    --left-sidenav-background: #1c324d;
    --main-font-color: #212b36;
    --beta-font-color: #637381;
    --hover-background-color: #f4f6f8;
    --left-sidenav-selected: #21446c;
    --selected-underline: #0c9afc;
    --chart-column-0: #015ccd;
    --chart-column-1: #71a4e3;
    --chart-column-2: #aac8ee;
    --chart-column-3: #c6daf3;
    --chart-column-4: #212b36;
    --chart-line-0: #6755c1;
    --primary-action-font-color: #1a9afc;
    --primary-action-background: #e8f5ff;
    --secondary-action-font-color: #637381;
    --secondary-action-background: #f4f5f6;

    --widget-header-color: #1c324d;
    --widget-header-hover-color: #21446c;
    --widget-header-font-color: #fff;

    --chat-body-color: #f4f6f8;
    --chc-message-outgoing-color: #edf9ff;

    --link-color: rgb(48, 154, 252);
    --converse-primary: rgb(48, 154, 252);

    --toastify-toast-min-height: 36px;

    --marvin-fw-bold: 700;
    --marvin-fw-semibold: 600;
    --marvin-fw-regular: 400;

    --converse-box-shadow: 0px 1px 2px #b9c4ce6c;
    --search-container-shadow: 0px 2px 4px #b9c4ce6c;
    --focused-filter-icon-bg-hover: #e7f1fb;
    --focused-filter-option-bg: #fff;
    --focused-filter-option-text: #454f5b;
    --focused-filter-option-hover-bg: #f5f6f8;
    --focused-filter-option-selected-bg: #e7f1fb;
    --blue-color : #006fd6;
    --provider-icon-bg: #fff;
    --blue-button-text-color:  #fff;
    --white-button-text-color:  #141414;
    --disabled-link-color:rgba(0, 0, 0, 0.5);
    color: var(--main-font-color);
    --font-family-sans-serif: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
   
}

@font-face {
    font-family: "Regular";
    src: local("-apple-system"), local("BlinkMacSystemFont"), local("Segoe UI"),
        local("Roboto"), local("Helvetica Neue"), local("Arial"),
        local("sans-serif"),
        url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuib.ttf") format("truetype");
    font-weight: var(--marvin-fw-regular);
}

@font-face {
    font-family: "Bold";
    font-weight: var(--marvin-fw-bold);
    src: local("-apple-system"), local("BlinkMacSystemFont"), local("Segoe UI"),
        local("Roboto"), local("Helvetica Neue"), local("Arial"),
        local("sans-serif"),
        url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuib.ttf") format("truetype");
}

/* @font-face { font-family: 'Segoe UI Light';         src: url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuil.ttf") format("truetype"); } */

@font-face {
    font-family: "SemiBold";
    font-weight: var(--marvin-fw-semibold);
    src: local("-apple-system"), local("BlinkMacSystemFont"), local("Segoe UI"),
        local("Roboto"), local("Helvetica Neue"), local("Arial"),
        local("sans-serif"),
        url("https://lsqappcommoncontent.s3-ap-southeast-1.amazonaws.com/leadsquared/common/fonts/SegoeUI/segoeuisb.ttf") format("truetype");
}

body {
    margin: 0;
    font-family: var(--font-family-sans-serif);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

span::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.light-mode {
    --converse-base: rgb(var(--marvin-base));
    --main-background-color: rgb(var(--marvin-background-1));

    --widget-header-color: rgb(var(--marvin-primary-dark));
    --widget-header-hover-color: rgba(var(--marvin-primary-dark), 0.9);
    --widget-header-canvas-background: #fff;

    --header-icon-color: rgb(var(--marvin-border-1));
    --header-icon-hover: rgba(var(--marvin-primary-dark), 0.9);

    --link-color: rgb(var(--marvin-primary));
    --converse-primary: rgb(var(--marvin-primary));

    --dropdown-selected: rgba(var(--marvin-primary), 0.1);
    --selected-underline: rgb(var(--marvin-primary));
    --focused-filter-icon-bg-hover: #e7f1fb;
    --focused-filter-option-bg: #fff;
    --focused-filter-option-text: #454f5b;
    --focused-filter-option-hover-bg: #f5f6f8;
    --focused-filter-option-selected-bg: #e7f1fb;
}

.cv-no-iframe-colors {
        --marvin-base: 255,255,255;
        --marvin-primary-dark: 23,43,77;
        --marvin-background-1: 249,250,251;
        --marvin-border-1: 202,211,219;
        --marvin-primary: 0,111,214;

        --converse-base: rgb(var(--marvin-base));
        --main-background-color: rgb(var(--marvin-background-1));
    
        --widget-header-color: rgb(var(--marvin-primary-dark));
        --widget-header-hover-color: rgba(var(--marvin-primary-dark), 0.9);
        --widget-header-canvas-background: #fff;
    
        --header-icon-color: rgb(var(--marvin-border-1));
        --header-icon-hover: rgba(var(--marvin-primary-dark), 0.9);
    
        --link-color: rgb(var(--marvin-primary));
        --converse-primary: rgb(var(--marvin-primary));
    
        --dropdown-selected: rgba(var(--marvin-primary), 0.1);
        --selected-underline: rgb(var(--marvin-primary));
        --focused-filter-icon-bg-hover: #e7f1fb;
        --focused-filter-option-bg: #fff;
        --focused-filter-option-text: #454f5b;
        --focused-filter-option-hover-bg: #f5f6f8;
        --focused-filter-option-selected-bg: #e7f1fb;

}

.dark-mode {
    --hover-background-color: rgb(29, 29, 29);
    --marvin-chat-header: 231, 241, 251;
    --converse-gray-3: rgb(var(--marvin-divider-1));
    --converse-base: rgb(var(--marvin-base));
    --converse-gray-5: rgb(var(--marvin-background-1));

    --main-background-color: rgb(var(--marvin-background-1));
    --main-background-color-2: rgb(var(--marvin-background-2));
    --main-border-color: rgb(var(--marvin-border-1));
    --main-font-color: rgb(var(--marvin-primary-text));
    --beta-font-color: rgb(var(--marvin-tertiary-text));
    --widget-header-color: rgb(var(--marvin-primary-light));
    --widget-header-hover-color: rgba(var(--marvin-primary-light), 0.9);
    --widget-header-canvas-background: #000;
    --widget-header-font-color: rgb(var(--marvin-base));

    --chat-body-color: rgb(var(--marvin-background-1));

    --chc-message-outgoing-color: rgba(33, 43, 54, 1);
    --chc-message-incoming-color: rgb(var(--marvin-divider-1));
    --converse-box-shadow: 0px 1px 1px #0000006c;
    --search-container-shadow: 0px 2px 4px #0000006c;
    --chc-message-autoresponse-color:  #141414;

    --converse-dropdown-hover: rgb(var(--marvin-background-1));
    --dropdown-selected: rgb(var(--marvin-primary), 0.1);
    --dropdown-text-color: rgb(var(--main-font-color));

    --composer-input-color: rgb(var(--main-font-color));

    --template-unapproved-color: rgb(71, 56, 36);
    --template-name-hover-color: rgb(250, 250, 250);

    --search-box-color: var(--main-font-color);

    --header-icon-color: rgb(var(--marvin-border-1));
    --header-icon-hover: rgba(var(--marvin-primary-light));
    --icon-color: rgb(210 210 210);

    --shimmer-effect-dark: linear-gradient(to right, #242424 0%, #343434 100%);

    color: var(--main-font-color);
    background-color: var(--main-background-color);

    --link-color: rgb(var(--marvin-primary));
    --selected-underline: rgb(var(--marvin-primary));
    --converse-primary: rgb(var(--marvin-primary));
    --focused-filter-icon-bg-hover: #2C99FD33;
    --focused-filter-option-bg: #434343;
    --focused-filter-option-text: #fafafa;
    --focused-filter-option-hover-bg: rgb(29, 29, 29);
    --focused-filter-option-selected-bg:#2C99FD33;
    --blue-color: #2C99FD;
    --provider-icon-bg: #242424;
    --blue-button-text-color: #141414;
    --white-button-text-color: #fff;
    --disabled-link-color:rgb(145, 158, 171);
    --brown-bg-color: rgb(48,48,48)
} 

.shimmer-effect {
    animation: shimmer 2s infinite;
    background: var(--shimmer-effect-dark,
            linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%));
    background-size: 1000px 100%;
}

.shimmer-effect-overlay:before {
    animation: shimmer 2s infinite;
    background: var(--shimmer-effect-dark,
            linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%));
    background-size: 1000px 100%;
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.shimmer-effect-overlay {
    position: relative;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

/* 
.cv-widget-container *, .cv-main-wrapper * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */
.thin-scroll {
    scrollbar-color: slategrey transparent;
    scrollbar-width: thin;
}

.thin-scroll::-webkit-scrollbar {
    width: 4px;
}

.thin-scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    height: 10px;
}

@-webkit-keyframes GradientShimmerDiv {
    0% {
        left: 5%;
    }

    50% {
        left: 75%;
    }

    100% {
        left: 95%;
    }
}

@-moz-keyframes GradientShimmerDiv {
    0% {
        left: 5%;
    }

    50% {
        left: 75%;
    }

    100% {
        left: 95%;
    }
}

@keyframes GradientShimmerDiv {
    0% {
        left: 5%;
    }

    50% {
        left: 75%;
    }

    100% {
        left: 95%;
    }
}

@keyframes slide {
    10% {
        width: 10%;
        transition: 250ms;
    }

    100% {
        width: 95%;
        transition: 250ms;
    }
}

*:hover {
    outline: none;
}

/* Overriding Platform CSS for Converse widget */
.cv-widget-container textarea[readonly] {
    background: none;
}

/* Marvin */
.marvin {
    --notification-color: rgba(222, 54, 24, 1);
}

/* Modal CSS */

.cv-widget-warning-canvas {
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
    top: 0px;
    background-color: #000000bf;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 99;
}

.cv-widget-warning-canvas.cv-fullscreen {
    position: relative;
    border-radius: 4px;
    width: 400px;
}

.cv-unsaved-changes {
    padding: 20px 16px 16px 20px;
    background-color: #fff;
}

.cv-unsaved-changes>div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
}

.cv-unsaved-changes>div:nth-child(1)>div:nth-child(2) {
    cursor: pointer;
}

.cv-unsaved-changes>div:nth-child(2) {
    font-size: 12px;
    margin-top: 12px;
}

.cv-unsaved-changes>div:nth-child(3) {
    display: flex;
    flex-direction: row-reverse;
    font-size: 12px;
    margin-top: 20px;
}

.cv-unsaved-changes>div:nth-child(3)>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 25px;
    border: 1px solid #c4cdd5;
    border-radius: 2px;
    cursor: pointer;
}

.cv-unsaved-changes>div:nth-child(3)>div:nth-child(1) {
    background-color: #006fd6;
    color: #fff;
}

.cv-unsaved-changes>div:nth-child(3)>div:nth-child(2) {
    margin-right: 8px;
}